import Vue from 'vue';
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 注册样式
import '@/assets/css/install.js';
import App from './App.vue';
import router from './router';
import store from './store';
import './utils/request';
Vue.use(Element);
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: createElement => createElement(App)
}).$mount('#app');