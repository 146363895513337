import Vue from 'vue';
import VueRouter from 'vue-router';
import config from '@/config';
import store from '@/store';
import { MessageBox } from 'element-ui';
Vue.use(VueRouter);
const routerMap = [{
  path: '/',
  name: 'Home',
  component: () => import('@/views/index')
}, {
  name: 'Payslip',
  path: '/payslip',
  component: () => import('@/views/payslip/Payslip'),
  meta: {
    title: '工资条'
  }
}, {
  name: 'PayslipList',
  path: '/payslip-list',
  component: () => import('@/views/payslip/PayslipList'),
  meta: {
    title: '工资条列表'
  }
}, {
  name: 'PayslipNoData',
  path: '/payslip-noData',
  component: () => import('@/views/payslip/PayslipNoData'),
  meta: {
    title: '工资条列表'
  }
}, {
  name: 'PayslipDetail',
  path: '/payslip-detail',
  component: () => import('@/views/payslip/PayslipDetail'),
  meta: {
    title: '工资条详情'
  }
}, {
  name: 'PayslipAuthority',
  path: '/payslip-authority',
  component: () => import('@/views/payslip/PayslipAuthority'),
  meta: {
    title: '管理权限',
    permission: true,
    perms: [config.QY_ADMIN]
  }
}, {
  name: 'PayslipManager',
  path: '/payslip-manager',
  component: () => import('@/views/payslip/PayslipManager'),
  meta: {
    title: '工资条管理',
    permission: true,
    perms: [config.QY_ADMIN, config.ADMIN]
  }
}, {
  name: 'SalaryDetailList',
  path: '/salary-detail-list',
  component: () => import('@/views/payslip/SalaryDetailList'),
  meta: {
    title: '工资表明细',
    permission: true,
    perms: [config.QY_ADMIN, config.ADMIN]
  }
}, {
  name: 'PayslipUpload',
  path: '/payslip-upload',
  component: () => import('@/views/payslip/PayslipUpload'),
  meta: {
    title: '工资条上传',
    permission: true,
    perms: [config.QY_ADMIN, config.ADMIN]
  }
}, {
  name: 'NotFound',
  path: '/404',
  component: () => import('@/views/errorPage/NotFound')
},
// 404页面一定要最后加载，如果放在constantRoutes一同声明了404，后面的所以页面都会被拦截到404
{
  path: '*',
  redirect: '/404'
}];
const router = new VueRouter({
  routes: routerMap
});
const handlePermission = (to, from, next) => {
  const {
    permission = false,
    perms = []
  } = to.meta;
  if (permission) {
    const {
      roleName
    } = store.getters;
    if (perms.includes(roleName)) {
      return next();
    } else {
      return MessageBox.alert('抱歉，您没有权限访问该页面', '提示', {
        customClass: 'no-permission-message',
        confirmButtonText: '确定',
        callback: () => {
          next({
            path: '/',
            replace: true
          });
        }
      });
    }
  } else {
    return next();
  }
};
router.beforeEach((to, from, next) => {
  var _to$meta;
  // 根据每个页面的 titleKey 设置页面 title
  document.title = (to === null || to === void 0 || (_to$meta = to.meta) === null || _to$meta === void 0 ? void 0 : _to$meta.title) || '工资条';
  handlePermission(to, from, next);
});
export default router;