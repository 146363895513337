import Storage from '@/utils/storage';
export default {
  namespaced: true,
  state: {
    uploadRecordId: Storage.sessionGet('uploadRecordId') || '',
    // 上传文件ID
    uploadStep: Storage.sessionGet('uploadStep') || 0,
    // 当前上传步骤
    // 工资条列表参数
    salaryListQuery: Storage.sessionGet('salaryListQuery') || {
      year: '',
      month: ''
    }
  },
  mutations: {
    // 设置上传文件ID
    setUploadRecordId(state, uploadRecordId) {
      Storage.sessionSet('uploadRecordId', uploadRecordId);
      state.uploadRecordId = uploadRecordId;
    },
    // 设置当前上传步骤
    setUploadStep(state, uploadStep) {
      Storage.sessionSet('uploadStep', uploadStep);
      state.uploadStep = uploadStep;
    },
    // 设置工资条列表参数
    setSalaryListQuery(state, salaryListQuery) {
      Storage.sessionSet('salaryListQuery', salaryListQuery);
      state.salaryListQuery = salaryListQuery;
    }
  },
  getters: {
    uploadRecordId: state => state.uploadRecordId,
    uploadStep: state => state.uploadStep,
    salaryListQuery: state => state.salaryListQuery
  }
};