import { buildUrl } from '@/utils/util';

// 企微重新登录
export const weWorkReLogin = () => {
  // 企微内登录token失效 重新登录
  const corpId = process.env.VUE_APP_WEWORK_APPID;
  const agentId = process.env.VUE_APP_WEWORK_AGENTID;
  const redirectUri = window.location.origin;
  const weWorkUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize';
  const params = {
    appid: corpId,
    redirect_uri: redirectUri,
    response_type: 'code',
    scope: 'snsapi_base',
    state: 'STATE',
    agentid: agentId
  };
  const fullUrl = buildUrl(weWorkUrl, params);
  const url = `${fullUrl}#wechat_redirect`;
  window.location.replace(url);
};