import Storage from '@/utils/storage';
import { userLogin } from '@/api/salary';
export default {
  namespaced: true,
  state: {
    roleName: Storage.localGet('roleName') || '',
    // 角色名称
    token: Storage.localGet('token') || '' // token
  },
  mutations: {
    // 设置角色名称
    setRoleName(state, roleName) {
      Storage.localSet('roleName', roleName);
      state.roleName = roleName;
    },
    setToken(state, token) {
      Storage.localSet('token', token);
      state.token = token;
    }
  },
  actions: {
    // 用户根据code登录
    async userLoginByCode({
      commit
    }, {
      code
    }) {
      const res = await userLogin({
        code
      });
      if (res !== null && res !== void 0 && res.data) {
        const {
          token = '',
          roleName = ''
        } = res.data;
        commit('setToken', token);
        commit('setRoleName', roleName);
      }
      return Promise.resolve(res);
    }
  }
};