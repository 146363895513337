import axios from 'axios';
import qs from 'qs';
/**
 * 企业微信用户
 * 超级管理员添加管理员
 */
export const userInsertAdminUser = async (data, options) => {
  return axios.post('/users', data, {
    ...options
  });
};

// 获取角色列表
export const getRoles = async (params, options) => {
  return axios.get('/roles', {
    params,
    ...options
  });
};

/**
 * 工资详情
 * 根据用户token获取工资详情列表
 */
export const salaryDetailGetSalaryListByUserId = async (params, options) => {
  return axios.get('/salary-details/user/list', {
    params,
    ...options
  });
};

/**
 * 企业微信用户
 * 推送月度发放工资消息接口
 */
export const userSendMessage = async (data, options) => {
  return axios.post('/users/message/send', data, {
    ...options
  });
};
/**
 * 上传工资记录
 * 上传工资信息excel
 */
export const uploadRecordUploadSalaryExcel = async (data, options) => {
  return axios.post('/salary-details/upload/salary/excel', data, {
    ...options
  });
};

/**
 * 企业微信用户
 * 获取访问用户身份
 */
export const userLogin = async (params, options) => {
  return axios.get('/users/login', {
    params,
    ...options
  });
};

/**
 * 企业微信用户
 * 获取admin用户列表信息
 */
export const userGetAdminUserList = async (params, options) => {
  return axios.get('/users/page', {
    params,
    ...options
  });
};

/**
 * 企业微信用户
 * 删除用户的管理员权限
 */
export const userRemoveAdminAuth = async (data, options) => {
  return axios.put('/users', data, {
    ...options
  });
};

/**
 * 工资详情
 * 根据日期查询工资详情信息
 */
export const salaryDetailGetSalaryDetail = async (params, options) => {
  return axios.get('/salary-details/select/date/salaryDetail', {
    params,
    ...options
  });
};

/**
 * 工资详情
 * 工资信息无误的情形
 */
export const salaryDetailNormalConfirmSalaryDetail = async (params, options) => {
  return axios.get('/salary-details/confirm/normal', {
    params,
    ...options
  });
};

/**
 * 工资详情
 * 反馈工资存在问题
 */
export const salaryDetailErrorForConfirm = async (params, options) => {
  return axios.get('/salary-details/confirm/error', {
    params,
    ...options
  });
};

/**
 * 上传工资记录
 * 根据上传记录统计工资详情信息
 */
export const uploadRecordGetUploadRecordInfo = async (params, options) => {
  return axios.get('/salary-details/select/statistics/salaryDetail', {
    params,
    ...options
  });
};

/**
 * 上传工资记录
 * 删除文件及工资详情表中的细节
 */
export const uploadRecordRemoveByUploadId = async (params, options) => {
  return axios.delete(`/uploadrecords?${qs.stringify(params)}`, {
    ...options
  });
};

/**
 * 上传工资记录
 * 查询UploadRecord错误详情
 */
export const uploadRecordGetUploadRecordErrorLog = async (params, options) => {
  return axios.get('/uploadrecords', {
    params,
    ...options
  });
};

/**
 * 工资详情
 * 更新工资详情表中的userId
 */

export const salaryDetailUpdateSalaryDetailUserId = async (params, options) => {
  return axios.put(`/salary-details/update/userId?${qs.stringify(params)}`, {
    ...options
  });
};

/**
 * 工资详情
 * 根据uploadId获取工资列表详情信息
 */
export const salaryDetailGetSalaryDetailPageList = async (params, options) => {
  return axios.get('/salary-details/pageList/by/uploadId', {
    params,
    ...options
  });
};

/**
 * 工资详情
 * 详情
 */
export const salaryDetailInfo = async (params, options) => {
  return axios.get('/salary-details', {
    params,
    ...options
  });
};