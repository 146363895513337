import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
export const downloadFile = ({
  data,
  contentType,
  fileName
}) => {
  const blob = new Blob([data], {
    type: `${contentType};charset=utf-8`
  });
  const downloadElement = document.createElement('a');
  const href = window.URL.createObjectURL(blob);
  downloadElement.href = href;
  downloadElement.download = fileName;
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement); // 下载完成移除元素
  window.URL.revokeObjectURL(href); // 释放掉blob对象
};

/**
 *
 * @param {string} url 下载这个文件的路径
 * @param {String} name 下载下来的文件名称
 */
export const fileDownload = (url, name) => {
  const aTag = document.createElement('a');
  aTag.href = url;
  aTag.download = name;
  aTag.style.display = 'none';
  document.body.appendChild(aTag);
  aTag.click();
  document.body.removeChild(aTag);
};

/**
 * @description 中国标准时间转换为自己想要的格式
 * 用法 formatChinaTime(dateValue,'yyyy-MM-dd HH:mm:ss')
 * @param time 时间戳
 * @param {string} format 格式
 * @returns {string} 格式化后的时间
 */
export function formatChinaTime(time, format) {
  const date = new Date(time);
  const tf = item => (item < 10 ? '0' : '') + item;
  return format.replace(/yyyy|MM|dd|HH|mm|ss/g, type => {
    switch (type) {
      case 'yyyy':
        return tf(date.getFullYear());
      case 'MM':
        return tf(date.getMonth() + 1);
      case 'mm':
        return tf(date.getMinutes());
      case 'dd':
        return tf(date.getDate());
      case 'HH':
        return tf(date.getHours());
      case 'ss':
        return tf(date.getSeconds());
      default:
        return '';
    }
  });
}

/**
 * @description 获取当前年份
 * @returns {number} 当前年份
 */
export const getCurrentYear = () => new Date().getFullYear();

/**
 * @description 获取当前月份
 * @returns {number} 当前月份
 */
export const getCurrentMonth = () => new Date().getMonth() + 1;

/**
 * @description 判断端
 * @returns {string} 端类型 PC端|移动端
 */
export const getClientType = () => {
  const userAgentRegex = new RegExp(['phone', 'pad', 'pod', 'iPhone', 'iPod', 'ios', 'iPad', 'Android', 'Mobile', 'BlackBerry', 'IEMobile', 'MQQBrowser', 'JUC', 'Fennec', 'wOSBrowser', 'BrowserNG', 'WebOS', 'Symbian', 'Windows Phone'].join('|'), 'i');
  return userAgentRegex.test(window.navigator.userAgent) ? 'mobile' : 'pc';
};

/**
 * @param {Function} fn 需要防抖的函数
 * @param {*} delay 时间间隔
 * @returns {(function(): void)|*}
 */
export const debounce = (fn, delay = 500) => {
  let timer = null;
  return function debounceFn() {
    if (timer) {
      clearTimeout(timer);
    }
    const args = arguments;
    timer = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
};

/**
 * @param {Function} fn 需要节流的回调函数
 * @param delay 时间间隔
 * @returns {(function(): void)|*}
 */
export const throttle = (fn, delay = 500) => {
  let timer = null;
  return function throttled() {
    if (timer) {
      return;
    }
    timer = setTimeout(function timerFn() {
      fn.apply(this, arguments);
      timer = null;
    }, delay);
  };
};

/**
 * @description 获取URL中的参数, 并返回键值对的集合
 * 示例url: a=1&b=2
 * @param {string} query
 * @returns {Object} 对象 {a: 1,b: 2}
 */
export const queryStringToObj = query => {
  const obj = {};
  const queryArray = query.split('&');
  queryArray.forEach(element => {
    const item = element.split('=');
    obj[item[0]] = item[1];
  });
  return obj;
};

/**
 * @description 获取url问号后参数
 * @param {string} url 地址
 * @returns {Object}
 */
export const getUrlParamsObj = url => {
  let query = url;
  if (url.indexOf('?') !== -1) {
    query = url.split('?')[1];
  }
  return queryStringToObj(query);
};

// url转义 ^是对特殊符号<,>,&的前导字符
export const urlEscape = url => url.replace(/&/g, '^&');

// url解义 ^是对特殊符号<,>,&的前导字符
export const urlUnescape = url => url.replace(/\^&/g, '&');

/**
 * @description 对象转为字符串&拼接
 * 示例obj: {a: 1,b: 2}
 * @param {Object} obj 对象
 * @returns {string} string a=1&b=2
 */
export const objToQueryString = obj => {
  const keys = Object.keys(obj);
  const queryString = keys.map(key => `${key}=${obj[key]}`).join('&');
  return queryString;
};

/**
 * @description url参数拼接
 * @param {string} url
 * @param {Object} params
 * @returns
 */
export const buildUrl = (url, params) => {
  url += '?';
  let keys = Object.keys(params);
  for (let key of keys) {
    url += `${key}=${encodeURIComponent(params[key])}&`;
  }
  return url.substring(0, url.length - 1);
};