import axios from 'axios';
import { Loading, MessageBox } from 'element-ui';
import Storage from '@/utils/storage';
import resetMessage from '@/utils/resetMessage';
import { weWorkReLogin } from '@/utils/login';
import { buildUrl } from '@/utils/util';

// 请求超时的时间限制
const timeout = 60 * 1000; // 60s
axios.defaults.timeout = timeout;
const {
  NODE_ENV,
  VUE_APP_BASE_URL
} = process.env;
axios.defaults.baseURL = NODE_ENV === 'development' ? '/api' : VUE_APP_BASE_URL;

// 当页面有两个接口时，第一个接口loading的close事件会直接将第二个接口的loading实例也close
let loadingInstance = null;
const startLoading = loadingConfig => {
  loadingInstance = Loading.service({
    fullscreen: true,
    text: '拼命加载中...',
    ...loadingConfig
  });
};
const endLoading = () => {
  loadingInstance.close();
};
let needLoadingRequestCount = 0;
const showFullScreenLoading = loadingConfig => {
  if (needLoadingRequestCount === 0) {
    startLoading(loadingConfig);
  }
  needLoadingRequestCount++;
};
const tryHideFullScreenLoading = () => {
  if (needLoadingRequestCount <= 0) {
    return;
  }
  needLoadingRequestCount--;
  if (needLoadingRequestCount === 0) {
    endLoading();
  }
};
function addAuthorizationHeader(config, jwtToken) {
  if (jwtToken) {
    config.headers.Authorization = jwtToken;
  }
  return config;
}

// 开始设置请求 发起的拦截处理
axios.interceptors.request.use(config => {
  const jwtToken = Storage.localGet('token') || '';
  config = addAuthorizationHeader(config, jwtToken);
  if (!config.hideLoading) {
    showFullScreenLoading(config.loadingConfig ? config.loadingConfig : {});
  }
  let {
    url
  } = config;
  if (config.method === 'get' && config.params) {
    url = buildUrl(url, config.params);
    config.params = {};
  }
  config.url = url;
  return config;
}, error => {
  tryHideFullScreenLoading();
  return Promise.reject(error);
});

// 请求到结果的拦截处理
axios.interceptors.response.use(config => {
  var _config$data;
  tryHideFullScreenLoading();
  const {
    headers
  } = config;
  if (headers['content-type'].includes('application/octet-stream')) {
    return config;
  }
  if ((config === null || config === void 0 || (_config$data = config.data) === null || _config$data === void 0 ? void 0 : _config$data.ok) === false) {
    const {
      code
    } = config.data.error;
    const handler = errorCodeHandler[code] || errorCodeHandler.default;
    handler(config);
  }
  return config.data;
}, error => {
  tryHideFullScreenLoading();
  if (error !== null && error !== void 0 && error.response) {
    switch (error.response.status) {
      case 400:
        error.message = '请求错误';
        break;
      case 401:
        error.message = '未授权，请重新登录';
        break;
      case 403:
        error.message = '抱歉，您暂无该接口资源权限，请联系管理员';
        break;
      case 500:
        error.message = '服务器错误';
        break;
      case 501:
        error.message = '服务未实现';
        break;
      case 502:
        error.message = '网络错误';
        break;
      case 503:
        error.message = '服务不可用';
        break;
      case 504:
        error.message = '网络超时';
        break;
      default:
        error.message = `连接出错(${error.response.status})！`;
    }
  } else if (error !== null && error !== void 0 && error.message.includes('timeout')) {
    // 判断请求异常信息中是否含有超时timeout字符串
    error.message = '连接超时，请重试';
  } else {
    error.message = '连接服务器出错！';
  }
  resetMessage({
    type: 'error',
    message: error.message
  });
  return Promise.reject(error);
});
const errorCodeHandler = {
  'salarysheet.ex.10030': () => {
    resetMessage.error('企业超级管理员不允许变更角色权限');
  },
  'salarysheet.ex.10032': config => {
    return config.data;
  },
  'salarysheet.ex.-10002': () => {
    MessageBox.alert('尊敬的用户，由于您长时间未操作，需要重新登录', '提示', {
      confirmButtonText: '重新登录',
      showClose: false
    }).then(() => {
      weWorkReLogin();
    });
  },
  default: config => {
    resetMessage.error(config.data.error.msg);
  }
};